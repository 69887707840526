/*
## Authentification

- La plupart des requêtes à l'API doivent être authentifiées (token dans authorization-header)
- Si un userToken est en localStorage : requête de connexion de l'utilisateur (LOGIN : vérif. token et récupération des infos)
- Sinon : Requête avec login/password (AUTH: vérif. identifiants, retourne un token) pour la connexion de l'utilisateur.

## Notes

	- DISPATCH : actions (invoquées hors ou depuis le store)
	- COMMIT : mutations (uniquement invoquées par le store)
	
	Les mutations mettent à jour l'état de l'application (`state`).

## LocalStorage

	Certaines données sont enregistrées localement : App fait un `subscribe` sur les mutations du Store et lance l'action (`dispatch`) `STORAGE_SYNC`
	Lorsque les mutations observées sont appelées (`commit`) les valeurs du `state` sont enregistrées (userToken, etc.)
	De cette manière le localStorage est uniquement modifié par l'action STORAGE_SYNC, lui même invoqué par des mutations.

	Certaines données du state (ex. utilisateur) sont chargées automatiquement depuis le locatStorage.

*/
import { appConfig } from "./config.js";
import Vue from "vue";

// Store
import Vuex from "vuex";
Vue.use(Vuex);

// Axios
import { HTTP } from "./http-common.js";

// LocalStorage
// This will register the instance `Vue.$localStorage`
import Storage from "vue-web-storage";
Vue.use(Storage);

// Interval
import requestAnimFrame from "@/libs/request-timeout-interval/requestAnimFrame.js"; // clearRequestTimeout, …
import { requestInterval } from "@/libs/request-timeout-interval/requestInterval.js";

// Helpers
import { get } from "./libs/helpers.js";

// System updates clock reference var
var _clock = null;

// Store
export default new Vuex.Store({
  state: {
    // Authentication
    userToken: Vue.$localStorage.get("userToken", null),
    userData: Vue.$localStorage.get("userData", null),

    // System updates check (questions, chat, …)
    clock: _clock,
    updates: Vue.$localStorage.get("updates", {}),

    // Dashboard
    dashboardFilters: Vue.$localStorage.get("dashboardFilters", null),
    dashboardSearch: "",

    // Questions load options
    questionsLoadOptions: Vue.$localStorage.get("questionsLoadOptions", {
      limit: "last-months",
      includeComplete: false,
    }),

    // Local question draft
    questionDraft: Vue.$localStorage.get("questionDraft", null),

    // Question experts chat messages
    currentQuestionId: 0,
    chatMessages: [],

    // User IP from France?
    isFromFrance: Vue.$localStorage.get("isFromFrance", null),

    // Dashboard Help displayed by defautl
    helpDisplayStatus: true,

    // Dashboard pagination
    currentPaginationOptions: {},

    // Week Questions pagination
    currentWeekQuestionsPaginationOptions: {},
  },

  /**
   *
   *
   * MUTATIONS
   * L'App root surveille les mutation avec subscribe.
   */
  mutations: {
    // Update questions load options
    UPDATE_QUESTIONS_LOAD_OPTIONS: (state, options) => {
      state.questionsLoadOptions = options;
      Vue.$localStorage.set("questionsLoadOptions", state.questionsLoadOptions);
    },

    // Update current question
    CURRENT_QUESTION: (state, currentQuestionId) => {
      state.currentQuestionId = parseInt(currentQuestionId);
    },

    // Update state with new user data
    EXPERTS_CHAT_MESSAGES: (state, chatMessages) => {
      state.chatMessages = chatMessages;
    },

    SYSTEM_UPDATES: (state, updates) => {
      state.updates = updates;
    },

    STOP_CLOCK: (state) => {
      if (state.clock) {
        clearRequestInterval(_clock);
        state.clock = null;
      }
    },

    START_CLOCK: (state, clock) => {
      state.clock = clock;
    },

    HELP_DISPLAY_STATUS: (state, helpDisplayStatus) => {
      state.helpDisplayStatus = helpDisplayStatus;
    },

    // Save user IP country resolution
    FROM_FRANCE: (state, isFromFrance) => {
      state.isFromFrance = isFromFrance;
    },

    STORE_DASHBOARD_SEARCH: (state, payload) => {
      state.dashboardSearch = payload;
    },

    STORE_DASHBOARD_FILTERS: (state, payload) => {
      state.dashboardFilters = payload;
    },

    RESET_QUESTION_DRAFT: (state) => {
      state.questionDraft = null;
    },

    // Update the local question data
    // dataFragment can be questionDraft.themes, questionDraft.details, etc.
    SAVE_QUESTION_DRAFT: (state, payload) => {
      const questionID = parseInt(payload.questionID) || 0;
      state.questionDraft = {
        ...state.questionDraft,
        ...payload.dataFragment,
        questionID: questionID,
      };
    },

    // Update state with new user data
    UPDATE_USER_DATA: (state, userData) => {
      state.userData = userData;
    },

    // Retrieve authentified user data
    USER_LOGIN: (state, userData) => {
      state.userData = userData;
    },
    USER_LOGOUT: (state, userData) => {
      state.userToken = null;
      state.userData = null;
      state.questionDraft = null;
      state.isFromFrance = null;
    },

    // Update user token (Authorization Header)
    AUTH_SUCCESS: (state, userToken) => {
      state.userToken = userToken;
    },
    AUTH_LOGOUT: (state) => {
      state.userToken = "";
      state.userData = "";
    },

    // Update state with new current page for Week Questions Page
    SAVE_WEEK_QUESTIONS_CURRENT_PAGE: (state, weekQuestionsCurrentPage) => {
      state.currentWeekQuestionsPaginationOptions.savedCurrentPage = weekQuestionsCurrentPage;
    },
    // Update state with new current per page for Week Questions Page
    SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE: (state, weekQuestionsCurrentPerPage) => {
      state.currentWeekQuestionsPaginationOptions.perPage = weekQuestionsCurrentPerPage;
    },
  },

  /**
   *
   *
   * ACTIONS
   */
  actions: {
    // Update questions load options
    UPDATE_QUESTIONS_LOAD_OPTIONS: ({ commit }, options) => {
      commit("UPDATE_QUESTIONS_LOAD_OPTIONS", options);
    },

    // Get questions updates
    GET_SYSTEM_UPDATES: async ({ state, commit, dispatch }) => {
      // we can't access the reponse of a navigator.sendBeacon
      // const requestQueued = navigator.sendBeacon(`${appConfig.API_BASE_URL}/updates?jwt=${state.userToken}`);

      // abort if clock is canceled (logout)
      if (!state.clock || !state.userToken) {
        dispatch("STOP_CLOCK");
        return;
      }

      // Check for updates
      const updates = await HTTP.get("/updates")
        .then(function(response) {
          const payload = response.data.payload || null;
          return payload;
        })
        .catch(function(error) {
          return false;
        });

      // Reload questions
      if (state.updates?.questions && state.updates?.questions !== updates.questions) {
        dispatch("FETCH_QUESTIONS", { search: "", params: [] });
      }

      // Reload experts chat messages
      if (state.currentQuestionId && state.updates?.messages && state.updates.messages !== updates.messages) {
        dispatch("GET_EXPERTS_CHAT_MESSAGES", state.currentQuestionId);
      }

      // Update updates!
      commit("SYSTEM_UPDATES", updates);
    },

    STOP_CLOCK: ({ commit }) => {
      commit("STOP_CLOCK");
    },

    START_CLOCK: ({ state, commit, dispatch }) => {
      if (!state.userData || !!state.clock) {
        return;
      }

      // start clock (pass the global "_clock" by reference)
      _clock = requestInterval(() => {
        return dispatch("GET_SYSTEM_UPDATES");
      }, 6000);

      commit("START_CLOCK", _clock);
    },

    CURRENT_QUESTION: ({ commit }, questionID) => {
      commit("CURRENT_QUESTION", questionID);
    },

    // Update dashboard help display status
    HELP_DISPLAY_STATUS: ({ commit, dispatch }, status) => {
      commit("HELP_DISPLAY_STATUS", status);
    },

    // Update assignments
    PUT_ASSIGNMENT: ({ commit, dispatch }, assignment) => {
      return new Promise((resolve, reject) => {
        HTTP.put("/assignments", assignment)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Fetch assignments
    GET_ASSIGNMENTS: ({ commit, dispatch }, { year, period }) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`/assignments/${year}/${period}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Check if user IP is from France
    FROM_FRANCE: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("/is_from_france")
          .then(function(response) {
            const payload = response.data.payload;
            commit("FROM_FRANCE", payload);
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Stripe Create Customer Portal Session
     *
     * @param String Validation JWT
     * @returns
     */
    STRIPE_CUSTOMER_PORTAL_SESSION: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("/subscriptions/customer_portal_session", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Stripe Create Checkout Session
     *
     */
    STRIPE_CHECKOUT_SESSION: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("/subscriptions/checkout_session", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Stripe – Create new pending subscription for wire payment
     *
     */
    STRIPE_PENDING_WIRE_PAYMENT: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.post("/subscriptions/wire_payment")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Confirm subscription to the newsletter
     *
     * @param String Validation JWT
     * @returns
     */
    NEWSLETTER_CONFIRM_SUBSCRIPTION: ({ commit, dispatch }, { validationToken }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`/newsletter/confirm-subscription/${validationToken}`)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Confirm unsubscription from the newsletter
     *
     * @param String Validation JWT
     * @returns
     */
    NEWSLETTER_CONFIRM_UNSUBSCRIPTION: ({ commit, dispatch }, { validationToken }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`/newsletter/confirm-unsubscription/${validationToken}`)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Assign expert (userID) to question (questionID)
    POST_ASSIGN_EXPERT: ({ commit, dispatch }, { questionID, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/expert`, { expert_id: userID })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Clear current chat messages in store
    RESET_EXPERTS_CHAT_MESSAGES: ({ commit }) => {
      commit("EXPERTS_CHAT_MESSAGES", []);
    },

    // Get chat message of a question
    GET_EXPERTS_CHAT_MESSAGES: ({ commit, dispatch }, questionID) => {
      if (!questionID) return;

      return new Promise((resolve, reject) => {
        HTTP.get(`chat/${questionID}/messages`)
          .then(function(response) {
            const payload = response.data.payload || null;
            commit("EXPERTS_CHAT_MESSAGES", payload);
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Get all expert users
    GET_EXPERT_USERS: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("users/experts")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Post answer to question
    POST_ANSWER: ({ commit, dispatch }, { questionID, payload }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/answers`, payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Fetch question feedback
    GET_FEEDBACK: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/feedback`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // User Feedback
    SEND_FEEDBACK: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("feedback", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Remember dashboard filters
    STORE_DASHBOARD_FILTERS: ({ commit, dispatch }, payload) => {
      commit("STORE_DASHBOARD_FILTERS", payload);
    },

    // Remember dashboard search
    STORE_DASHBOARD_SEARCH: ({ commit }, payload) => {
      commit("STORE_DASHBOARD_SEARCH", payload);
    },

    // Update question read status
    PUT_QUESTION_READ_STATUS: ({ commit, dispatch }, { questionID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/read`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            console.error("PUT_QUESTION_READ_STATUS", error);
            reject(error);
          });
      });
    },

    // Update question pending validation status
    PUT_QUESTION_PENDING_VALIDATION_STATUS: ({ commit, dispatch }, { questionID, pendingValidation }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/pending-validation/${pendingValidation}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update question urgent status
    PUT_QUESTION_URGENT_STATUS: ({ commit, dispatch }, { questionID, urgentStatus }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/urgent/${urgentStatus}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update question starred status
    PUT_QUESTION_STARRED_STATUS: ({ commit, dispatch }, { questionID, starredStatus, type }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`questions/${questionID}/starred/${starredStatus}?type=${type}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Delete question ("draft" status only)
    DELETE_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`questions/${questionID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // New question: save local question draft
    RESET_QUESTION_DRAFT: ({ commit, dispatch }) => {
      commit("RESET_QUESTION_DRAFT");
    },

    // Edit question: save local question draft
    SAVE_QUESTION_DRAFT: ({ commit, dispatch }, payload) => {
      commit("SAVE_QUESTION_DRAFT", payload);
    },

    // Set mulitple questions status to "publish"
    COMPLETE_QUESTIONS: ({ commit, dispatch }, questionIDs) => {
      return new Promise((resolve, reject) => {
        HTTP.post("questions/complete", { qids: questionIDs })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Set question status to "publish"
    COMPLETE_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/complete`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Set question status to "publish"
    PUBLISH_QUESTION: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}/publish`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Insert/Update a question (from a question draft)
    // TODO: drafts dataFragment?
    SAVE_QUESTION: ({ state, commit, dispatch }) => {
      let data = state.questionDraft;
      let questionID = parseInt(data.questionID);
      let req = null;

      // Insert/Update question
      req = new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionID}`, data)
          .then(function(response) {
            const payload = response.data.payload || null;
            const questionID = payload?.questionID || null;
            // Update local draft
            commit("SAVE_QUESTION_DRAFT", {
              dataFragment: {},
              questionID: questionID,
            });
            resolve(questionID);
          })
          .catch(function(error) {
            reject(error);
          });
      });
      return req;
    },

    // Récupération des thématiques
    GET_THEMES: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("themes")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Delete question reference
    DELETE_DOCUMENT: ({ commit, dispatch }, hash) => {
      return new Promise((resolve, reject) => {
        HTTP.delete("files", { data: hash })
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération d'une question
    GET_QUESTION_DATA: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des réponses
    GET_ANSWERS: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/answers`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des questions
    // NOTE: Requête POST avec paramètres de pagination, tri, filtres, …
    // NOTE: les infos d'identification de l'utilisateur se trouvent dans le JWT envoyé avec chaque requête.
    // On récupère donc uniquement les questions qui concernent l'utilisateur connecté, en fonction des ses permissions.
    FETCH_QUESTIONS: ({ commit, dispatch }, { search, params }) => {
      const data = {
        search: search,
        params: params,
      };

      return new Promise((resolve, reject) => {
        HTTP.post("questions/search", data)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Récupération des questions
    // NOTE: les infos d'identification de l'utilisateur se trouvent dans le JWT envoyé avec chaque requête.
    // On récupère donc uniquement les questions qui concernent l'utilisateur connecté, en fonction des ses permissions.
    GET_QUESTIONS: ({ commit, dispatch }, { search, params }) => {
      // Search?
      const searchQuery = search ? `?s=${search}` : "";

      return new Promise((resolve, reject) => {
        HTTP.get(`questions${searchQuery}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    GET_WEEK_QUESTIONS: ({ commit, dispatch }, { year, week, page, perPage }) => {
      const searchQuery = `week-questions/${year}/${week}?page=${page}&perPage=${perPage}`;

      return new Promise((resolve, reject) => {
        HTTP.get(searchQuery)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    SAVE_WEEK_QUESTIONS_CURRENT_PAGE: ({ commit }, weekQuestionsCurrentPage) => {
      commit("SAVE_WEEK_QUESTIONS_CURRENT_PAGE", weekQuestionsCurrentPage);
    },

    SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE: ({ commit }, weekQuestionsCurrentPerPage) => {
      commit("SAVE_WEEK_QUESTIONS_CURRENT_PER_PAGE", weekQuestionsCurrentPerPage);
    },

    GET_DEFAULT_LINKS: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        HTTP.get("links/default")
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    DELETE_DEFAULT_LINKS: ({ commit, dispatch }, linkId) => {
      return new Promise((resolve, reject) => {
        HTTP.delete(`links/default/${linkId}`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    SAVE_DEFAULT_LINK: ({ commit, dispatch }, newLink) => {
      return new Promise((resolve, reject) => {
        HTTP.put("links/default", newLink)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    GET_QUESTION_LINKS: ({ commit, dispatch }, questionID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`questions/${questionID}/links`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    SAVE_QUESTION_LINK: ({ commit, dispatch }, { questionId, links }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`questions/${questionId}/links`, links)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Authentification
    AUTH_REQUEST({ commit, dispatch }, loginData) {
      return new Promise((resolve, reject) => {
        // API call to authenticate user
        HTTP.post("sign-in", loginData)
          .then(function(response) {
            const payload = response.data ? response.data.payload || null : null;
            const userToken = payload?.userToken || null;
            if (userToken === null) {
              dispatch("AUTH_LOGOUT");
              reject("userToken is null");
            } else {
              commit("AUTH_SUCCESS", userToken); // update status (mutation)
              resolve(response);
            }
          })
          .catch(function(error) {
            dispatch("AUTH_LOGOUT");
            console.info("Authentification error", error);
            reject("Authentification error", error);
          });
      });
    },

    // Dés-authentification
    AUTH_LOGOUT: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        commit("AUTH_LOGOUT");
        commit("STOP_CLOCK");
        resolve();
      });
    },

    // Connexion de l'utilisateur authentifié (le JWT est passé automatiquement dans les en-têtes de chaque requête)
    USER_LOGIN: ({ state, commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        // API call to login user
        HTTP.post("login")
          .then(function(response) {
            const userData = response?.data.payload || null;

            if (userData === null) {
              console.error("Unexpected error", "userData is null");
              dispatch("AUTH_LOGOUT");
              reject("userData is null");
            } else {
              // update status (mutation)
              commit("USER_LOGIN", userData);
              // start polling
              dispatch("START_CLOCK");
              resolve(response);
            }
          })
          .catch(function(err) {
            // login error
            dispatch("AUTH_LOGOUT");
            reject(err);
          });
      });
    },

    // Déconnexion et désauthentification de l'utilisateur
    USER_LOGOUT: ({ commit, dispatch, state }) => {
      return new Promise((resolve, reject) => {
        // Unvalidate tokens
        HTTP.post("logout")
          .then(function(response) {
            const payload = response.data.payload || null;
          })
          .catch(function(error) {
            reject(error);
          });

        // Update State and Storage
        commit("USER_LOGOUT");
        commit("STOP_CLOCK");
        resolve();
      });
    },

    // Reset password
    RESET_PASSWORD: ({ commit, dispatch }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("reset-password", payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Request a password reset (send a password reset token by email)
    REQUEST_PASSWORD_RESET: ({ commit, dispatch }, email) => {
      return new Promise((resolve, reject) => {
        HTTP.post("request-password-reset", { email: email })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Requestion new validation email
    NEW_VALIDATION_EMAIL: ({ commit, dispatch, state }, email) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users/validate", { email: email })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    UPDATE_EXPERT_ONCALL: ({ commit, dispatch, state }, { userID, status }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`users/experts/${userID}/${status}`)
          .then(function(response) {
            const updated = response?.data?.payload ? true : false;
            if (updated) {
              resolve(updated);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Check email validation token
    USER_VALIDATE: ({ commit, dispatch, state }, { userID, validationToken }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/validate/${validationToken}`)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Request email update
    REQUEST_EMAIL_UPDATE: ({ commit, dispatch }, { payload, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/request-email-update`, payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update email
    UPDATE_EMAIL: ({ commit, dispatch }, { token, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/update-email`, { token: token })
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Update password
    UPDATE_PASSWORD: ({ commit, dispatch }, { payload, userID }) => {
      return new Promise((resolve, reject) => {
        HTTP.put(`users/${userID}/update-password`, payload)
          .then(function(response) {
            const result = response.data.payload || null;
            resolve(result);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Profile: update user
    UPDATE_USER_DATA: ({ commit, dispatch, state }, { userID, formData }) => {
      return new Promise((resolve, reject) => {
        // NOTE: The server (o2switch) does not support send multipart/form-data with PUT method (payload is empty)
        // Spoof "PUT" method to send multipart/form-data
        // https://stackoverflow.com/questions/54686218/laravel-vuejs-axios-put-request-formdata-is-empty
        // formData.append("_method", "put");

        HTTP.post(`users/${userID}/update-profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function(response) {
            let userData = response.data.payload || null;
            if (userData) {
              commit("UPDATE_USER_DATA", userData);
              resolve(userData);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Registration: create user
    INSERT_USER_DATA: ({ commit, dispatch, state }, { userID, formData }) => {
      return new Promise((resolve, reject) => {
        HTTP.post(`users/${userID}/profile`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(function(response) {
            let userData = response.data.payload || null;
            // User profile created -> login user
            commit("USER_LOGIN", userData);
            resolve(response);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // Registration: create user
    CREATE_USER: ({ commit, dispatch, state }, payload) => {
      return new Promise((resolve, reject) => {
        HTTP.post("users", payload)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Refresh Current User Data
     *
     * @param Int userID
     */
    REFRESH_USER: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        // load data
        dispatch("GET_USER_DATA", userID).then((response) => {
          // then commit UPDATE_USER_DATA to refresh state and localStorage
          commit("UPDATE_USER_DATA", response);
          // And return fresh user data
          resolve(response);
        });
      });
    },

    // Profile: get user data
    GET_USER_DATA: ({ commit, dispatch }, userID) => {
      return new Promise((resolve, reject) => {
        HTTP.get(`users/${userID}/profile`)
          .then(function(response) {
            const payload = response.data.payload || null;
            resolve(payload);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    // localStorage Sync
    // L'App root subscribe aux mutations du Store pour mettre en cache les données de certaines mutations.
    STORAGE_SYNC: ({ commit, state }, mutation) => {
      return new Promise((resolve, reject) => {
        // console.log("STORAGE_SYNC", mutation, state);

        if (mutation.type === "SYSTEM_UPDATES") {
          Vue.$localStorage.set("updates", state.updates);
          resolve(mutation);
        }

        if (mutation.type === "FROM_FRANCE") {
          Vue.$localStorage.set("isFromFrance", state.isFromFrance);
          resolve(mutation);
        }

        if (mutation.type === "STORE_DASHBOARD_FILTERS") {
          Vue.$localStorage.set("dashboardFilters", state.dashboardFilters);
          resolve(mutation);
        }

        if (mutation.type === "RESET_QUESTION_DRAFT") {
          Vue.$localStorage.remove("questionDraft");
          resolve(mutation);
        }

        if (mutation.type === "SAVE_QUESTION_DRAFT") {
          Vue.$localStorage.set("questionDraft", state.questionDraft);
          resolve(mutation);
        }

        // Authentification réussie, on enregistre le userToken
        if (mutation.type === "AUTH_SUCCESS") {
          Vue.$localStorage.set("userToken", state.userToken);
          resolve(mutation);
        }

        // Erreur d'authentification ou Logout on supprime le userToken
        if (mutation.type === "AUTH_LOGOUT") {
          Vue.$localStorage.remove("userToken");
          Vue.$localStorage.remove("userData");
          resolve(mutation);
        }

        // Connexion de l'utilisateur : on enregistre ses données
        if (mutation.type === "USER_LOGIN" || mutation.type === "UPDATE_USER_DATA") {
          Vue.$localStorage.set("userData", state.userData);
          resolve(mutation);
        }

        // Erreur de login ou logout : on supprime les données de l'utilisateur
        if (mutation.type === "USER_LOGOUT") {
          Vue.$localStorage.clear(true);
          resolve(mutation);
        }

        // Si erreur : reject (mutation);
      });
    },
  },

  modules: {},
});
