<template>
	<footer class="app-footer">
		<help />

		<div class="card text-center">
			<p>Une solution de télé-expertise labellisée proposée par <strong>CrossDoc</strong>.</p>
			<p>
				<a href="https://crossdoc.fr">
					<img src="/images/CrossDoc-logo.svg"
							 alt="CrossDoc, télé-expertise labellisée"
							 height="56"
							 width="156" />
				</a>
			</p>
			<code class="app-version">v{{ appVersion }}</code>
		</div>
	</footer>
</template>

<style lang="scss"
			 scoped>
			@import "src/scss/99-utilities/utilities.align";
			@import "src/scss/99-utilities/utilities.text";

			.app-footer {
				padding: 1rem 1rem 6rem 1rem;

				img {
					display: inline;
				}
			}

			.card {
				padding: 1rem;
			}

			.flex-item {
				padding: 1rem;
				margin: 0 !important;
			}

			.app-version {
				display: block;
				margin-top: 1rem;
				font-size: .75rem;
				font-family: var(--font-monospace);
			}
		</style>

<script>
import { appConfig } from "../config.js";
import Help from "@/components/Help.vue";

export default {
	name: "AppFooter",
	components: {
		Help,
	},
	computed: {
		appVersion() {
			return appConfig.APP_VERSION;
		}
	}
};
</script>
